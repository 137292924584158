import { clsx } from 'clsx/lite';
import { type ReactNode, useCallback, useRef } from 'react';
import { mergeProps } from 'react-aria';
import type {
  ComboBoxProps,
  ListBoxItemProps,
  ListBoxProps,
  ListBoxSectionProps,
} from 'react-aria-components';
import {
  Button as RACButton,
  ComboBox as RACCombobox,
  Input as RACInput,
  Label as RACLabel,
  ListBox as RACListBox,
  ListBoxItem as RACListBoxItem,
  ListBoxSection as RACListBoxSection,
  Popover as RACPopover,
} from 'react-aria-components';
import { isFunction } from 'remeda';

import { Check } from '../../icons/check.js';
import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import { vars } from '../../theme-contract.css.js';
import { baseButtonStyles } from '../button/button.css.js';
import { Text } from '../text/text.js';
import {
  buttonStyles,
  comboBoxInputStyles,
  listBoxItemStyles,
  listBoxSectionStyles,
  popoverStyles,
  selectIconStyles,
} from './combobox.css.js';

export type ComboboxItem = {
  id: number | string;
  name?: string;
  children?: ComboboxItem[];
};

export interface ComboboxProps<T extends object = ComboboxItem>
  extends Omit<ComboBoxProps<T>, 'children'> {
  label?: string | ReactNode;
  css?: RainbowSprinkles;
  children: ReactNode | ((item: T) => ReactNode);
  placeholder?: string;
  inputSize?: number;
  divider?: boolean;
  popoverProps?: {
    maxHeight?: number;
  };
  renderEmptyState?: ListBoxProps<T>['renderEmptyState'];
}

export const Combobox = (props: ComboboxProps<ComboboxItem>) => {
  const {
    children,
    css,
    label,
    divider = false,
    items,
    inputSize = 20,
    popoverProps = { maxHeight: 200 },
    renderEmptyState: _renderEmptyState,
    ...restProps
  } = props;

  const renderEmptyState =
    _renderEmptyState ??
    (() => (
      <Text
        as="p"
        css={{ textAlign: 'center', padding: vars.space[8] }}
        kind={{ mobile: 'subtitle-4', medium: 'subtitle-4' }}
      >
        No results found. Please try again.
      </Text>
    ));

  const result = rainbowSprinkles({
    position: 'relative',
    ...css,
  });

  // These local handlers and ref (along with mergeProps down below 👇🏻) make it to where when
  // the user clicks on a combobox, the text is selected so that they can begin typing immediately
  // without having to manually clear the box first.
  //
  // Also, when a selection is made, the input will blur so that the cursor doesn't stay
  // active in the text box.
  //
  // [DEM 2025/02/07]
  const inputRef = useRef<HTMLInputElement>(null);

  const onSelectionChange = useCallback(() => {
    inputRef.current?.blur();
  }, []);

  const onOpenChange = useCallback((isOpen: boolean) => {
    if (isOpen) {
      inputRef.current?.select();
    }
  }, []);

  return (
    <RACCombobox
      allowsEmptyCollection={true}
      className={result.className}
      menuTrigger="focus"
      style={result.style}
      // This merges the local handlers with any that were passed in via props
      {...mergeProps(restProps, { onSelectionChange, onOpenChange })}
    >
      <RACLabel>{label}</RACLabel>
      {/* Do not remove - This Button comes from React Aria, it is not visible in the UI, but rendering it is required for full Combobox functionality */}
      <RACButton className={buttonStyles} />
      <RACInput
        className={clsx(baseButtonStyles, comboBoxInputStyles)}
        data-test="combobox-input"
        ref={inputRef}
        size={inputSize}
        type="text"
      />
      <RACPopover
        className={popoverStyles}
        data-show-divider={divider}
        data-test="combobox-popover"
        maxHeight={popoverProps.maxHeight}
      >
        <RACListBox
          data-test="combobox-list"
          items={items}
          renderEmptyState={renderEmptyState}
        >
          {children}
        </RACListBox>
      </RACPopover>
    </RACCombobox>
  );
};

export function ListBoxItem({
  children,
  markSelected = false,
  ...props
}: ListBoxItemProps & { markSelected?: boolean }) {
  return (
    <RACListBoxItem className={listBoxItemStyles} {...props}>
      {renderProps => {
        if (isFunction(children)) {
          return children(renderProps);
        }

        const { isSelected } = renderProps;

        return (
          <>
            {children}
            {isSelected || markSelected ?
              <Check className={selectIconStyles} size={18} />
            : null}
          </>
        );
      }}
    </RACListBoxItem>
  );
}

export function ListBoxSection({
  withDivider = false,
  section,
  ...props
}: ListBoxSectionProps<ComboboxItem> & {
  withDivider?: boolean;
  section: ComboboxItem;
}) {
  return (
    <RACListBoxSection
      className={listBoxSectionStyles}
      data-section-divider={withDivider}
      {...props}
    >
      {section.children?.map(item => {
        return (
          <ListBoxItem key={item.id} textValue={item.name}>
            {item.name}
          </ListBoxItem>
        );
      })}
    </RACListBoxSection>
  );
}
